html.dark {
  background-color: rgb(15, 23, 42);
}

#addtionalAlphabets
{
  visibility: hidden;
  color: green;
}

#aboutinfo
{
  text-align: justify;
}

#gamegrid
{
  border-color: gold;
}

#ncell
{
  border-color: gold;
}

#wordmeaning
{
  font-size: 23.5px;
}